import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import jsonData from './al-khaleel.json';

function App() {
  const [cpr, setCpr] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  

  useEffect(() => {
    document.body.setAttribute('dir', 'rtl');
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 9) {
      setCpr(value);
      setError('');
    } else {
      setError('CPR يجب أن يكون رقمًا مكونًا من 9 أرقام فقط.');
    }
  };

  const handleSearch = () => {
    setLoading(true);
    setError('');
    setResult(null);

    setTimeout(() => {
      const found = jsonData.find((item) => item["الرقم الشخصي"] === cpr);
      setResult(found);
      if (!found) setError('الرقم الشخصي غير موجود');
      setLoading(false);
    }, 500);
  };

  return (
    <div className="container mt-5">
     
      
      <div className="graybox" dir="rtl">
        <div className="form-group">
          <span>لمعرفة الصف، إدخال الرقم الشخصي</span>
          <input
            type="text"
            className="form-control"
            value={cpr}
            onChange={handleInputChange}
            placeholder="123456789"
            maxLength="9"
          />
          {error && <small className="text-danger">{error}</small>}
        </div>

        <button className="submit mx-auto d-block" onClick={handleSearch} disabled={loading || cpr.length !== 9}>
          {loading ? (
            <span>
              <i className="fas fa-spinner fa-spin"></i> جاري التحقق...
            </span>
          ) : (
            'بحــث'
          )}
        </button>
      </div>

      {result && (
        <div className="whitebox" dir="rtl">
          <div className="card-body">
            <div className="purplebox">بيانات الطالب</div>
            <div className="king">
              <span className="purple"><i class="bi bi-suit-diamond-fill"></i> الاســم&nbsp;: </span> {result["الاسم"]}
              <hr/>
             <span className="purple"><i class="bi bi-suit-diamond-fill"></i> الفرقة&nbsp;: </span> {result["الفرقة"]}
             <hr/>
            </div> 

          </div>
        </div>
      )}

      {!result && error && cpr.length === 9 && !loading && (
        <div className="alert alert-danger mt-4" role="alert">
          {error}
        </div>
      )}
    </div>
  );
}

export default App;
